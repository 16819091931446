/*=========================================================================================
File Name: state.js
Description: Vuex Store - state
----------------------------------------------------------------------------------------
Item Name: Vuesax Admin - VueJS Dashboard Admin Template
Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

import navbarSearchAndPinList from '@/layouts/components/navbarSearchAndPinList'
import themeConfig from '@/../themeConfig.js'
import colors from '@/../themeConfig.js'

const state = {
	isSidebarActive: true,
	breakpoint: null,
	sidebarWidth: "reduced",
	reduceButton: themeConfig.sidebarCollapsed,
	bodyOverlay: false,
	sidebarItemsMin: false,
	theme: themeConfig.theme || 'light',
	navbarSearchAndPinList: navbarSearchAndPinList,
	
	themePrimaryColor: colors.primary,
	
	starredPages: navbarSearchAndPinList.data.filter((page) => page.highlightAction),
	userRole: null,
	
	// Can be used to get current window with
	// Note: Above breakpoint state is for internal use of sidebar component
	windowWidth: null,
	
	// Takedown Data
	takedownDomains: [],
	
	// Unsuspension Data
	unsuspensionDomains: [],
	
	// Registry Lock Data
	registryLockDomains: [],
	
	// Anomaly Filter
	anomalyFilter: null,
	
	// Listing Count
	listingCount: {
		total: null,
		filtered: null
	},

	// Date Range
	dateRange: {
		columns: null,
		value: {
			column: '',
			startDate: null,
			endDate: null
		},
		active: false,
	},

	// Table Filter
	tableFilter: {
		options: null,
		value: null,
		active: false,
	},

	// Blacklisted Keywords
	blacklistedKeywords: [],
}

export default state
