/*=========================================================================================
  File Name: moduleAuthMutations.js
  Description: Auth Module Mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	UPDATE_AUTHENTICATED_USER(state, user) {
		// Clear User Object
		localStorage.removeItem('userDetails');
		// Set User Object
		localStorage.setItem('userDetails', JSON.stringify(user));
	},
	CLEAR_AUTHENTICATED_USER() {
		// Clear Access Token
		localStorage.removeItem('accessToken');
		// Clear User Object
		localStorage.removeItem('userDetails');
	},
}