/*=========================================================================================
File Name: actions.js
Description: Vuex Store - actions
----------------------------------------------------------------------------------------
Item Name: Vuesax Admin - VueJS Dashboard Admin Template
Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


const actions = {
	
	// ////////////////////////////////////////////
	// SIDEBAR & UI UX
	// ////////////////////////////////////////////
	
	updateSidebarWidth({ commit }, width) {
		commit('UPDATE_SIDEBAR_WIDTH', width);
	},
	updateI18nLocale({ commit }, locale) {
		commit('UPDATE_I18N_LOCALE', locale);
	},
	toggleContentOverlay({ commit }) {
		commit('TOGGLE_CONTENT_OVERLAY');
	},
	updateTheme({ commit }, val) {
		commit('UPDATE_THEME', val);
	},
	updateUserRole({ commit }, val) {
		commit('UPDATE_USER_ROLE', val);
	},
	updateWindowWidth({ commit }, width) {
		commit('UPDATE_WINDOW_WIDTH', width);
	},
	
	
	// ////////////////////////////////////////////
	// COMPONENT
	// ////////////////////////////////////////////
	
	// VxAutoSuggest
	updateStarredPage({ commit }, payload) {
		commit('UPDATE_STARRED_PAGE', payload)
	},
	
	//  The Navbar
	arrangeStarredPagesLimited({ commit }, list) {
		commit('ARRANGE_STARRED_PAGES_LIMITED', list)
	},
	// arrangeStarredPagesMore({ commit }, list) {
	// },
	
	// Takedown Domains
	setTakedownDomains: ({commit}, domains) => {
		commit('setTakedownDomains', domains)
	},
	clearTakedownDomains: ({commit}) => {
		commit('clearTakedownDomains')
	},
	
	// Unsuspension Domains
	setUnsuspensionDomains: ({commit}, domains) => {
		commit('setUnsuspensionDomains', domains)
	},
	clearUnsuspensionDomains: ({commit}) => {
		commit('clearUnsuspensionDomains')
	},
	
	// Registry Lock Domains
	setRegistryLockDomains: ({commit}, domains) => {
		commit('setRegistryLockDomains', domains)
	},
	clearRegistryLockDomains: ({commit}) => {
		commit('clearRegistryLockDomains')
	},
	
	// Anomaly Filter
	setAnomalyFilter: ({commit}, filter) => {
		commit('setAnomalyFilter', filter)
	},
	clearAnomalyFilter: ({commit}) => {
		commit('clearAnomalyFilter')
	},
	
	// Listing Count
	setListingTotalCount: ({commit}, count) => {
		commit('setListingTotalCount', count)
	},
	setListingFilteredCount: ({commit}, count) => {
		commit('setListingFilteredCount', count)
	},
	clearListingCount: ({commit}) => {
		commit('clearListingCount')
	},
	
	// Date Range
	setDateRangeValue: ({commit}, value) => {
		commit('setDateRangeValue', value)
	},
	clearDateRangeValue: ({commit}) => {
		commit('clearDateRangeValue')
	},
	setDateRangeColumns: ({commit}, columns) => {
		commit('setDateRangeColumns', columns)
	},
	clearDateRangeColumns: ({commit}) => {
		commit('clearDateRangeColumns')
	},
	setDateRangeActive: ({commit}) => {
		commit('setDateRangeActive')
	},
	clearDateRangeActive: ({commit}) => {
		commit('clearDateRangeActive')
	},
	
	// Table Filter
	setTableFilterValue: ({commit}, value) => {
		commit('setTableFilterValue', value)
	},
	clearTableFilterValue: ({commit}) => {
		commit('clearTableFilterValue')
	},
	setTableFilterOptions: ({commit}, options) => {
		commit('setTableFilterOptions', options)
	},
	clearTableFilterOptions: ({commit}) => {
		commit('clearTableFilterOptions')
	},
	setTableFilterActive: ({commit}) => {
		commit('setTableFilterActive')
	},
	clearTableFilterActive: ({commit}) => {
		commit('clearTableFilterActive')
	},
	
	// Blacklisted Keywords
	setBlacklistedKeywords: ({commit}, value) => {
		commit('setBlacklistedKeywords', value)
	},
	clearBlacklistedKeywords: ({commit}) => {
		commit('clearBlacklistedKeywords')
	},
}

export default actions
