/*=========================================================================================
  File Name: mutations.js
  Description: Vuex Store - mutations
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


const mutations = {

    // ////////////////////////////////////////////
    // SIDEBAR & UI UX
    // ////////////////////////////////////////////

    UPDATE_SIDEBAR_WIDTH(state, width) {
        state.sidebarWidth = width;
    },
    UPDATE_SIDEBAR_ITEMS_MIN(state, val) {
        state.sidebarItemsMin = val;
    },
    TOGGLE_REDUCE_BUTTON(state, val) {
        state.reduceButton = val;
    },
    TOGGLE_CONTENT_OVERLAY(state, val) {
        state.bodyOverlay = val;
    },
    TOGGLE_IS_SIDEBAR_ACTIVE(state, value) {
        state.isSidebarActive = value;
    },
    UPDATE_THEME(state, val) {
        state.theme = val;
    },
    UPDATE_WINDOW_BREAKPOINT(state, val) {
        state.breakpoint = val;
    },
    UPDATE_PRIMARY_COLOR(state, val) {
        state.themePrimaryColor = val;
    },
    UPDATE_USER_ROLE(state, val) {
        state.userRole = val
        localStorage.setItem('userRole', val)
    },
    UPDATE_WINDOW_WIDTH(state, width) {
      state.windowWidth = width;
    },


    // ////////////////////////////////////////////
    // COMPONENT
    // ////////////////////////////////////////////

    // VxAutoSuggest
    UPDATE_STARRED_PAGE(state, payload) {
        // find item index in search list state
        const index = state.navbarSearchAndPinList.data.findIndex((item) => item.index == payload.index)
        // update the main list
        state.navbarSearchAndPinList.data[index].highlightAction = payload.val;

        // if val is true add it to starred else remove
        if(payload.val) {
            state.starredPages.push(state.navbarSearchAndPinList.data[index])
        }else {
            // find item index from starred pages
            const index = state.starredPages.findIndex((item) => item.index == payload.index)
            // remove item using index
            state.starredPages.splice(index, 1);
        }
    },

    // The Navbar
    ARRANGE_STARRED_PAGES_LIMITED(state, list) {
        const starredPagesMore = state.starredPages.slice(10);
        state.starredPages = list.concat(starredPagesMore);
    },
    ARRANGE_STARRED_PAGES_MORE(state, list) {
        let downToUp = false
        let lastItemInStarredLimited = state.starredPages[10];
        const starredPagesLimited = state.starredPages.slice(0, 10);
        state.starredPages = starredPagesLimited.concat(list);

        state.starredPages.slice(0,10).map((i) => {
            if(list.indexOf(i) > -1) downToUp = true
        })
        if(!downToUp) {
            state.starredPages.splice(10, 0, lastItemInStarredLimited);
        }
    },

    // Takedown Domains
    setTakedownDomains: (state, domains) => {
        state.takedownDomains = domains;
    },
    clearTakedownDomains: (state) => {
        state.takedownDomains = [];
    },

    // Unsuspension Domains
    setUnsuspensionDomains: (state, domains) => {
        state.unsuspensionDomains = domains;
    },
    clearUnsuspensionDomains: (state) => {
        state.unsuspensionDomains = [];
    },

    // Registry Lock Domains
    setRegistryLockDomains: (state, domains) => {
        state.registryLockDomains = domains;
    },
    clearRegistryLockDomains: (state) => {
        state.registryLockDomains = [];
    },

    // Anomaly Filter
    setAnomalyFilter: (state, anomaly) => {
        state.anomalyFilter = anomaly;
    },
    clearAnomalyFilter: (state) => {
        state.anomalyFilter = null;
    },

    // Listing Count
    setListingTotalCount: (state, count) => {
        state.listingCount['total'] = count;
    },
    setListingFilteredCount: (state, count) => {
        state.listingCount['filtered'] = count;
    },
    clearListingCount: (state) => {
        state.listingCount['total'] = null;
        state.listingCount['filtered'] = null;
    },

    // Date Range
    setDateRangeValue: (state, value) => {
        state.dateRange['value']['column'] = value['column'];
        state.dateRange['value']['startDate'] = value['startDate'];
        state.dateRange['value']['endDate'] = value['endDate'];
    },
    clearDateRangeValue: (state) => {
        state.dateRange['value']['column'] = '';
        state.dateRange['value']['startDate'] = null;
        state.dateRange['value']['endDate'] = null;
    },
    setDateRangeColumns: (state, columns) => {
        state.dateRange['columns'] = columns;
    },
    clearDateRangeColumns: (state) => {
        state.dateRange['columns'] = null;
    },
    setDateRangeActive: (state) => {
        state.dateRange['active'] = true;
    },
    clearDateRangeActive: (state) => {
        state.dateRange['active'] = false;
    },

    // Table Filter
    setTableFilterValue: (state, value) => {
        state.tableFilter['value'] = value;
    },
    clearTableFilterValue: (state) => {
        state.tableFilter['value'] = null;
    },
    setTableFilterOptions: (state, options) => {
        state.tableFilter['options'] = options;
    },
    clearTableFilterOptions: (state) => {
        state.tableFilter['options'] = null;
    },
    setTableFilterActive: (state) => {
        state.tableFilter['active'] = true;
    },
    clearTableFilterActive: (state) => {
        state.tableFilter['active'] = false;
    },

    // Blacklisted Keywords
    setBlacklistedKeywords: (state, value) => {
        state.blacklistedKeywords = value;
    },
    clearBlacklistedKeywords: (state) => {
        state.blacklistedKeywords = [];
    },
}

export default mutations
