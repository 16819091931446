const getters = {
	getAnomalyFilter: state => {
        return state.anomalyFilter;
    },
	getListingCount: state => {
        return state.listingCount;
    },
	getDateRangeValue: state => {
        return state.dateRange['value'];
    },
	getDateRangeColumns: state => {
        return state.dateRange['columns'];
    },
	getDateRangeActive: state => {
        return state.dateRange['active'];
    },
	getTableFilterValue: state => {
        return state.tableFilter['value'];
    },
	getTableFilterOptions: state => {
        return state.tableFilter['options'];
    },
	getTableFilterActive: state => {
        return state.tableFilter['active'];
    },
	getTakedownDomains: state => {
        return state.takedownDomains;
    },
	getUnsuspensionDomains: state => {
        return state.unsuspensionDomains;
    },
	getRegistryLockDomains: state => {
        return state.registryLockDomains;
    },
	getBlacklistedKeywords: state => {
        return state.blacklistedKeywords;
    },
}

export default getters