/*=========================================================================================
File Name: router.js
Description: Routes for vue-router. Lazy loading is enabled.
Object Strucutre:
path => router path
name => router name
component(lazy loading) => component to load
meta : {
	rule => which user can have access (ACL)
	breadcrumb => Add breadcrumb to specific page
	pageTitle => Display title besides breadcrumb
}
----------------------------------------------------------------------------------------
Item Name: Vuesax Admin - VueJS Dashboard Admin Template
Author: Pixinvent
Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


import Vue from 'vue'
import Router from 'vue-router'
// import auth from "@/auth/authService";
import store from './store/store.js';

Vue.use(Router)

function requireAuth(to, from, next) {
	/*
		Determines where we should send the user.
	*/
	function proceed() {
		/*
			If the user is not empty, that means there's a user
			authenticated we allow them to continue. Otherwise, we
			send the user back to the home page.
		*/
		if (JSON.parse(localStorage.getItem('userDetails'))) {
			switch (to.meta.permission) {
				/*
					If the route that requires authentication is a user, then we continue.
					All users can access these routes
				*/
				/*
					If the route that requires authentication is a guest and the role
					the user has is 'guest' or 'admin', we allow
					access. Otherwise we redirect back to the home.
				*/
				case 'guest':
					if (JSON.parse(localStorage.getItem('userDetails')).role == 'guest' || JSON.parse(localStorage.getItem('userDetails')).role == 'admin') {
						next();
					} else {
						next('/');
					}
					break;
				/*
					If the route that requires authentication is a admin and the role
					the user has is 'admin', we allow
					access. Otherwise we redirect back to the home.
				*/
				case 'admin':
					if (JSON.parse(localStorage.getItem('userDetails')).role == 'admin') {
						next();
					} else {
						next('/');
					}
					break;
			}
		} else {
			// Clear user details
			store.dispatch('auth/clearAuthenticatedUser');
			// Redirect to login
			next('/pages/login');
		}
	}

	/*
		Auth Check AXIOS call
	*/
	router.app.$axiosSecure.get(`/auth-check`)
	.then((response) => {
		if (response) {
			// Proceed to route
			proceed();
		}
	})
	.catch(error  => {
		// If user is not logged in
		if (error.response && error.response.status == 401) {
			// Clear user details
			store.dispatch('auth/clearAuthenticatedUser');
			// Redirect to login
			next('/pages/login');
		}
	})
}


const router = new Router({
	mode: 'history',
	base: process.env.BASE_URL,
	scrollBehavior () {
		return { x: 0, y: 0 }
	},
	routes: [
		
		{
			// =============================================================================
			// MAIN LAYOUT ROUTES
			// =============================================================================
			path: '',
			component: () => import('./layouts/main/Main.vue'),
			children: [
				// =============================================================================
				// Theme Routes
				// =============================================================================
				{
					path: '/',
					name: 'home',
					component: () => import('./views/Home.vue'),
					beforeEnter: requireAuth,
					meta: {
						permission: 'guest'
					}
				},
				{
					path: '/new-registrations',
					name: 'new-registrations',
					component: () => import('./views/NewRegistrations.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'New Registrations', active: true },
						],
						buttons: {
							refresh: true,
							download: true,
							filter: true,
							blacklist: true
						},
						pageTitle: 'Registrations',
						permission: 'guest'
					},
					children: [
						{
							path: 'domain-preview/:id?',
							name: 'new-registrations-domain-preview',
							component: () => import('./views/DomainPreview.vue'),
							beforeEnter: requireAuth,
							meta: {
								breadcrumb: [{
									title: 'Home',
									url: '/'
									},
									{
									title: 'New Registrations',
									active: true
									},
								],
								buttons: {
									refresh: true,
									download: true
								},
								pageTitle: 'Registrations',
								permission: 'guest'
							},
						}
					],
				},
				{
					path: '/abuse-reported',
					name: 'abuse-reported',
					component: () => import('./views/AbuseReported.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Abuse Reported', active: true },
						],
						buttons: {
							refresh: true,
							download: true,
							filter: true
						},
						pageTitle: 'Abuse',
						permission: 'guest'
					},
					children: [
						{
							path: 'domain-preview/:id?',
							name: 'abuse-reported-domain-preview',
							component: () => import('./views/DomainPreview.vue'),
							beforeEnter: requireAuth,
							meta: {
								breadcrumb: [
									{ title: 'Home', url: '/' },
									{ title: 'Abuse Reported', active: true },
								],
								buttons: {
									refresh: true,
									download: true
								},
								pageTitle: 'Abuse',
								permission: 'guest'
							},
						}
					],
				},
				{
					path: '/suspension',
					name: 'suspension',
					component: () => import('./views/Suspension.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Suspension', active: true },
						],
						pageTitle: 'Abuse',
						permission: 'admin'
					}
				},
				{
					path: '/suspension-list',
					name: 'suspension-list',
					component: () => import('./views/SuspensionList.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Suspension List', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Suspensions',
						permission: 'guest'
					},
					children: [
						{
							path: 'domain-preview/:id?',
							name: 'suspension-list-domain-preview',
							component: () => import('./views/DomainPreview.vue'),
							beforeEnter: requireAuth,
							meta: {
								breadcrumb: [
									{ title: 'Home', url: '/' },
									{ title: 'Suspension List', active: true },
								],
								buttons: {
									refresh: true,
									download: true
								},
								pageTitle: 'Suspensions',
								permission: 'guest'
							},
						}
					],
				},
				{
					path: '/suspension-blocked',
					name: 'suspension-blocked',
					component: () => import('./views/SuspensionBlocked.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Suspension Blocked', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Suspensions',
						permission: 'guest'
					},
					children: [
						{
							path: 'domain-preview/:id?',
							name: 'suspension-blocked-domain-preview',
							component: () => import('./views/DomainPreview.vue'),
							beforeEnter: requireAuth,
							meta: {
								breadcrumb: [
									{ title: 'Home', url: '/' },
									{ title: 'Suspension Blocked', active: true },
								],
								buttons: {
									refresh: true,
									download: true
								},
								pageTitle: 'Suspensions',
								permission: 'guest'
							},
						}
					],
				},
				{
					path: '/suspension-not-actionable',
					name: 'suspension-not-actionable',
					component: () => import('./views/SuspensionNotActionable.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Suspension Not Actionable', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Suspensions',
						permission: 'guest'
					},
					children: [
						{
							path: 'domain-preview/:id?',
							name: 'suspension-not-actionable-domain-preview',
							component: () => import('./views/DomainPreview.vue'),
							beforeEnter: requireAuth,
							meta: {
								breadcrumb: [
									{ title: 'Home', url: '/' },
									{ title: 'Suspension Not Actionable', active: true },
								],
								buttons: {
									refresh: true,
									download: true
								},
								pageTitle: 'Suspensions',
								permission: 'guest'
							},
						}
					],
				},
				{
					path: '/unsuspension',
					name: 'unsuspension',
					component: () => import('./views/Unsuspension.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Unsuspension', active: true },
						],
						pageTitle: 'Unsuspensions',
						permission: 'admin'
					}
				},
				{
					path: '/unsuspension-requests',
					name: 'unsuspension-requests',
					component: () => import('./views/UnsuspensionRequests.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Unsuspension Requests', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Unsuspensions',
						permission: 'admin'
					}
				},
				{
					path: '/unsuspension-list',
					name: 'unsuspension-list',
					component: () => import('./views/UnsuspensionList.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Unsuspension List', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Unsuspensions',
						permission: 'guest'
					},
					children: [
						{
							path: 'domain-preview/:id?',
							name: 'unsuspension-list-domain-preview',
							component: () => import('./views/DomainPreview.vue'),
							beforeEnter: requireAuth,
							meta: {
								breadcrumb: [
									{ title: 'Home', url: '/' },
									{ title: 'Unsuspension List', active: true },
								],
								buttons: {
									refresh: true,
									download: true
								},
								pageTitle: 'Unsuspensions',
								permission: 'guest'
							},
						}
					],
				},
				{
					path: '/unsuspension-not-actionable',
					name: 'unsuspension-not-actionable',
					component: () => import('./views/UnsuspensionNotActionable.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Unsuspension Not Actionable', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Unsuspensions',
						permission: 'guest'
					},
					children: [
						{
							path: 'domain-preview/:id?',
							name: 'unsuspension-not-actionable-domain-preview',
							component: () => import('./views/DomainPreview.vue'),
							beforeEnter: requireAuth,
							meta: {
								breadcrumb: [
									{ title: 'Home', url: '/' },
									{ title: 'Unsuspension Not Actionable', active: true },
								],
								buttons: {
									refresh: true,
									download: true
								},
								pageTitle: 'Unsuspensions',
								permission: 'guest'
							},
						}
					],
				},
				{
					path: '/registry-lock/:type?',
					name: 'registry-lock',
					component: () => import('./views/RegistryLock.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Lock / Unlock', active: true },
						],
						pageTitle: 'Registry Lock',
						permission: 'guest'
					}
				},
				{
					path: '/registry-lock-list',
					name: 'registry-lock-list',
					component: () => import('./views/RegistryLockList.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Registry Lock List', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Registry Lock',
						permission: 'guest'
					},
					children: [
						{
							path: 'domain-preview/:id?',
							name: 'registry-lock-list-domain-preview',
							component: () => import('./views/DomainPreview.vue'),
							beforeEnter: requireAuth,
							meta: {
								breadcrumb: [
									{ title: 'Home', url: '/' },
									{ title: 'Registry Lock List', active: true },
								],
								buttons: {
									refresh: true,
									download: true
								},
								pageTitle: 'Registry Lock',
								permission: 'guest'
							},
						}
					],
				},
				{
					path: '/action-review',
					name: 'action-review',
					component: () => import('./views/ActionReview.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Suspensions, Unsuspensions, Lock & Unlock', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Action Review',
						permission: 'guest'
					}
				},
				{
					path: '/anomalies',
					name: 'Anomalies',
					component: () => import('./views/Anomalies.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Anomalies', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Anomalies',
						permission: 'guest'
					}
				},
				{
					path: '/search',
					name: 'search',
					component: () => import('./views/Search.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Search Details', active: true },
						],
						pageTitle: 'Search',
						permission: 'guest'
					}
				},
				{
					path: '/domain-search/:id?',
					name: 'domain-search',
					component: () => import('./views/DomainSearch.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Domain Search', active: true },
						],
						pageTitle: 'Domain Search',
						permission: 'guest'
					}
				},
				{
					path: '/registrant-search/:id?',
					name: 'registrant-search',
					component: () => import('./views/RegistrantSearch.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Registrant Search', active: true },
						],
						pageTitle: 'Registrant Search',
						permission: 'guest'
					}
				},
				{
					path: '/watchlist',
					name: 'watchlist',
					component: () => import('./views/Watchlist.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Watchlist', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Rule Management',
						permission: 'admin'
					}
				},
				{
					path: '/saved-filters',
					name: 'saved-filters',
					component: () => import('./views/SavedFilters.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Saved Filters', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Rule Management',
						permission: 'admin'
					}
				},
				{
					path: '/create-rts-rule',
					name: 'create-rts-rule',
					component: () => import('./views/RTSRuleCreate.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Create RTS Rule', active: true },
						],
						pageTitle: 'Rule Management',
						permission: 'admin'
					}
				},
				{
					path: '/rts-rule-list',
					name: 'rts-rule-list',
					component: () => import('./views/RTSRuleList.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'RTS Rule List', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Rule Management',
						permission: 'admin'
					}
				},
				{
					path: '/create-rtk-rule',
					name: 'create-rtk-rule',
					component: () => import('./views/RTKRuleCreate.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Create RTK Rule', active: true },
						],
						pageTitle: 'Rule Management',
						permission: 'admin'
					}
				},
				{
					path: '/rtk-rule-list',
					name: 'rtk-rule-list',
					component: () => import('./views/RTKRuleList.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'RTK Rule List', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Rule Management',
						permission: 'admin'
					}
				},
				{
					path: '/rtk-rule-watchlist',
					name: 'rtk-rule-watchlist',
					component: () => import('./views/RTKRuleWatchlist.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'RTK Rule Watchlist', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Rule Management',
						permission: 'admin'
					}
				},
				{
					path: '/request-sources',
					name: 'request-sources',
					component: () => import('./views/RequestSources.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Request Sources List', active: true },
						],
						buttons: {
							// daterange: true,
							refresh: true,
							download: true
						},
						pageTitle: 'Rule Management',
						permission: 'admin'
					}
				},
				{
					path: '/trusted-sources',
					name: 'trusted-sources',
					component: () => import('./views/TrustedSources.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Trusted Sources', active: true },
						],
						buttons: {
							// daterange: true,
							refresh: true,
							download: true
						},
						pageTitle: 'Rule Management',
						permission: 'admin'
					}
				},
				{
					path: '/add-blacklist-keyword',
					name: 'add-blacklist-keyword',
					component: () => import('./views/BlacklistKeywordAdd.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Add Blacklist Keyword', active: true },
						],
						pageTitle: 'Rule Management',
						permission: 'admin'
					}
				},
				{
					path: '/blacklist-keyword-list',
					name: 'blacklist-keyword-list',
					component: () => import('./views/BlacklistKeywordList.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Blacklist Keyword List', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Rule Management',
						permission: 'admin'
					}
				},
				{
					path: '/add-blacklist-registrant',
					name: 'add-blacklist-registrant',
					component: () => import('./views/BlacklistRegistrantAdd.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Add Blacklist Registrant', active: true },
						],
						pageTitle: 'Rule Management',
						permission: 'admin'
					}
				},
				{
					path: '/blacklist-registrant-list',
					name: 'blacklist-registrant-list',
					component: () => import('./views/BlacklistRegistrantList.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Blacklist Registrant List', active: true },
						],
						pageTitle: 'Rule Management',
						permission: 'admin'
					}
				},
				{
					path: '/whitelist',
					name: 'whitelist',
					component: () => import('./views/Whitelist.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Whitelist', active: true },
						],
						pageTitle: 'Rule Management',
						permission: 'admin'
					}
				},
				{
					path: '/whitelist-alerts',
					name: 'whitelist-alerts',
					component: () => import('./views/WhitelistAlerts.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Whitelist Alerts', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Rule Management',
						permission: 'admin'
					}
				},
				{
					path: '/registrars',
					name: 'Registrars',
					component: () => import('./views/Registrars.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Registrar Details', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Registrars',
						permission: 'admin'
					}
				},
				{
					path: '/source-detection/artists-against-419',
					name: 'artists-against-419',
					component: () => import('./views/source-detection/ArtistsAgainst419.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Artists Against 419', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/easysol',
					name: 'easysol',
					component: () => import('./views/source-detection/Easysol.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Easysol', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/google-safe-browsing',
					name: 'google-safe-browsing',
					component: () => import('./views/source-detection/GoogleSafeBrowsing.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Google Safe Browsing', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/malware-domains',
					name: 'malware-domains',
					component: () => import('./views/source-detection/MalwareDomains.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Malware Domains', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/netcraft',
					name: 'netcraft',
					component: () => import('./views/source-detection/Netcraft.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Netcraft', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/ntldstats',
					name: 'ntldstats',
					component: () => import('./views/source-detection/NTLDstats.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'nTLDstats', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/phishlabs',
					name: 'phishlabs',
					component: () => import('./views/source-detection/Phishlabs.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Phishlabs', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/phishtank',
					name: 'phishtank',
					component: () => import('./views/source-detection/Phishtank.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Phishtank', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/segasec',
					name: 'segasec',
					component: () => import('./views/source-detection/Segasec.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Segasec', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/spamhaus',
					name: 'spamhaus',
					component: () => import('./views/source-detection/Spamhaus.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Spamhaus', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/spamhaus-dbl',
					name: 'spamhaus-dbl',
					component: () => import('./views/source-detection/SpamhausDBL.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Spamhaus DBL', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/surbl',
					name: 'surbl',
					component: () => import('./views/source-detection/Surbl.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Surbl', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/symantec',
					name: 'symantec',
					component: () => import('./views/source-detection/Symantec.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Symantec', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/urlhaus',
					name: 'urlhaus',
					component: () => import('./views/source-detection/URLhaus.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'URLhaus', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/ctm360',
					name: 'CTM360',
					component: () => import('./views/source-detection/CTM360.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'CTM360', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/cybercrimecenter',
					name: 'C3 Cyber Crime Center',
					component: () => import('./views/source-detection/CyberCrimeCenter.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'C3 Cyber Crime Center', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/memcyco',
					name: 'MemcyCo',
					component: () => import('./views/source-detection/MemcyCo.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'MemcyCo', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/cybergs',
					name: 'Cybergs',
					component: () => import('./views/source-detection/Cybergs.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Cyber.gs', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/hispasec',
					name: 'Hispasec',
					component: () => import('./views/source-detection/Hispasec.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Hispasec', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/dga-feed',
					name: 'DGA Feed',
					component: () => import('./views/source-detection/DGAFeed.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'DGA Dump Feed', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/dga-regex',
					name: 'DGA Regex',
					component: () => import('./views/source-detection/DGARegex.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'DGA Regex Feed', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/dga-api',
					name: 'DGA API',
					component: () => import('./views/source-detection/DGAAPI.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'DGA API Feed', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/netcraft-scrapped',
					name: 'Netcraft Scrapped',
					component: () => import('./views/source-detection/NetcraftScrapped.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Netcraft Scrapped', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/orange',
					name: 'Orange',
					component: () => import('./views/source-detection/Orange.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Orange', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/openphish',
					name: 'Openphish',
					component: () => import('./views/source-detection/Openphish.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Openphish', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/source-detection/radix-suspension-requests',
					name: 'Radix Suspension Requests',
					component: () => import('./views/source-detection/RadixSuspensionRequests.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Radix Suspension Requests', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Source Detection',
						permission: 'guest'
					},
				},
				{
					path: '/registrar-report',
					name: 'Registrar Report',
					component: () => import('./views/reports/RegistrarReport.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Registrar Report', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Reports',
						permission: 'guest'
					}
				},
				{
					path: '/dns-twister',
					name: 'DNS Twister',
					component: () => import('./views/DNSTwister.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'DNS Twister Report', active: true },
						],
						pageTitle: 'DNS Twister',
						permission: 'guest'
					}
				},
				{
					path: '/registrant-abuse-rate',
					name: 'registrant-abuse-rate',
					component: () => import('./views/highlights/RegistrantAbuseRate.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Registrant Abuse Rate', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Highlights',
						permission: 'guest'
					}
				},
				{
					path: '/bulk-registrations',
					name: 'bulk-registrations',
					component: () => import('./views/highlights/BulkRegistrations.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Bulk Registrations', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Highlights',
						permission: 'guest'
					}
				},
				{
					path: '/daily-patterns',
					name: 'daily-patterns',
					component: () => import('./views/highlights/DailyPatterns.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Daily Patterns', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Highlights',
						permission: 'guest'
					}
				},
				{
					path: '/abusive-daily-patterns',
					name: 'abusive-daily-patterns',
					component: () => import('./views/highlights/AbusiveDailyPatterns.vue'),
					beforeEnter: requireAuth,
					meta: {
						breadcrumb: [
							{ title: 'Home', url: '/' },
							{ title: 'Abusive Daily Patterns', active: true },
						],
						buttons: {
							refresh: true,
							download: true
						},
						pageTitle: 'Highlights',
						permission: 'guest'
					}
				},
			],
		},
		// =============================================================================
		// FULL PAGE LAYOUTS
		// =============================================================================
		{
			path: '',
			component: () => import('@/layouts/full-page/FullPage.vue'),
			children: [
				// =============================================================================
				// PAGES
				// =============================================================================
				{
					path: '/callback',
					name: 'authCallback',
					component: () => import('@/views/Callback.vue'),
					meta: {
						permission: 'guest'
					}
				},
				{
					path: '/pages/login',
					name: 'pageLogin',
					component: () => import('@/views/pages/Login.vue'),
					meta: {
						permission: 'guest'
					}
				},
				{
					path: '/pages/error-404',
					name: 'pageError404',
					component: () => import('@/views/pages/Error404.vue'),
					meta: {
						permission: 'guest'
					}
				},
			]
		},
		// Redirect to 404 page, if no match found
		{
			path: '*',
			redirect: '/pages/error-404'
		}
	],
})

router.afterEach(() => {
	// Remove initial loading
	const appLoading = document.getElementById('loading-bg');
	if (appLoading) {
		appLoading.style.display = "none";
	}
	// Remove body loading class
	const appBody = document.body;
	if (appBody) {
		appBody.classList.remove("body-loading");
	}
})

router.beforeEach((to, from, next) => {
	// Add body loading class
	const appBody = document.body;
	if (appBody) {
		appBody.classList.add("body-loading");
	}
	
	// firebase.auth().onAuthStateChanged(() => {
	
	//     // get firebase current user
	//     const firebaseCurrentUser = firebase.auth().currentUser;
	
	//     if (
	//         to.path === "/pages/login" ||
	//         to.path === "/pages/forgot-password" ||
	//         to.path === "/pages/error-404" ||
	//         to.path === "/pages/error-500" ||
	//         to.path === "/pages/register" ||
	//         to.path === "/callback" ||
	//         to.path === "/pages/comingsoon" ||
	//         (auth.isAuthenticated() || firebaseCurrentUser)
	//     ) {
	//         return next();
	//     }
	
	//     router.push({ path: '/pages/login', query: { to: to.path } })
	//     // Specify the current path as the customState parameter, meaning it
	//     // will be returned to the application after auth
	//     // auth.login({ target: to.path });
	
	// });
	
	// Custom login
	// if (
	// 	to.path === "/pages/login" ||
	// 	localStorage.getItem('userInfo')
	// 	) {
	// 		return next();
	// 	}
		
	// 	router.push({ path: '/pages/login', query: { to: to.path } })

	return next();
		
});
	
export default router
	