/*=========================================================================================
  File Name: moduleAuthActions.js
  Description: Auth Module Actions
  ----------------------------------------------------------------------------------------
  Item Name: Vuesax Admin - VueJS Dashboard Admin Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/


export default {
	updateAuthenticatedUser({ commit }, payload) {
		commit('UPDATE_AUTHENTICATED_USER', payload)
	},
	clearAuthenticatedUser({ commit }) {
		commit('CLEAR_AUTHENTICATED_USER')
	}
}
